.cart-items {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 20rem;
    overflow: auto;
  }

  .background {
    background-color: #1F2840
  }
  
  .cart-info {
    display: flex;
    justify-content: space-between;
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 25px;
  }

  .total label {
    margin-right: 10px;
  }

  .form-container {
    display: flex;
    justify-content: space-between;
  }

  .form-instructions {
    font-size: 19px;
    /* font-weight: bold; */
    color: white;
    margin-top: 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: right;
    row-gap: 10px;
  }
  
  .email-input {
    display: flex;
    flex-direction: column;
    align-items: right;
  }

  /* .email-input label {

  } */

  .email-input p {
    color: #cd0f51;
  }

  .email-input p::before {
    display: inline;
    content: "⚠ ";
  }

  .email-input input {
    margin-top: 6px;
  }

  .order-placed {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .buttons {
    display: flex;
    justify-content: right;
  }

  .buttons button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #66FCF1;
    color: #66FCF1;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
  }

  .buttons button:hover,
  .buttons button:active {
    background: #66FCF1;
    color: #1F2840;
    transition: all 0.3s ease-out;
  }
  
  .buttons .button--alt {
    color: #8a2b06;
  }
  
  .buttons .button {
    background-color: transparent;
    color: white;
  }

  @media screen and (max-width: 500px) {
    .form-container {
      flex-direction: column;
      align-items: center;
    }

    .actions {
      align-items: center;
      row-gap: 10px;
    }

    .email-input {
      align-items: center;
      margin-right: 40px;
    }

    .form-instructions {
      font-size: 19px;
      /* font-weight: bold; */
      color: white;
      margin-top: 0;
      text-align: center;
    }


  }