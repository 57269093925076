.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 0;
    display: flex;
    justify-content: center;
  }

.modal {
    position: fixed;
    top: 20vh;
    width: 40rem;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0px 2px 18px 5px rgba(0,0,0,0.3);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }
  
  @media screen and (max-width: 500px) {
    .modal {
      left: 4%;
      max-width: 85%;
    }
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }