.input {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .input label {
    color: white;
    font-weight: bold;
    margin-right: 1rem;
  }
  
  .input input {
    border-radius: 5px;
    border: 1px solid #1F2830;
    font: inherit;
    padding-left: 0.5rem;
    background-color: #1F2830;
    color: white;
  }