.form {
    text-align: right;
  }
  
  .form button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #66FCF1;
    color: #66FCF1;
    padding: 0.25rem 2rem;
    border-radius: 20px;
    font-weight: bold;
  }

  .form input {
    width: 3rem;
  }
  
  .form button:hover,
  .form button:active {
    background: #66FCF1;
    color: #242424;
    transition: all 0.3s ease-out;
  }