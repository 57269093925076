.cart-button {
    cursor: pointer;
    font: inherit;
    border: 1px solid #66FCF1;
    background-color: transparent;
    color: #66FCF1;
    padding: 0.75rem 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
  }
  
  .cart-button:hover,
  .cart-button:active {
    background: #66FCF1;
    color: #242424;
    transition: all 0.3s ease-out;
  }
  
  .icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .badge {
    background-color: #66FCF1;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    margin-left: 1rem;
    color: #242424;
    font-weight: bold;
  }
  
  .cart-button:hover .badge,
  .cart-button:active .badge {
    color: white;
    background-color: #1F2833;
  }
  
  .bump {
    animation: bump 300ms ease-out;
  }
  
  @keyframes bump {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(0.9);
    }
    30% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 500px) {
    .cart-button {
      padding: .5rem 1.5rem;
    }
  }