.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    background-color: #1F2840;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 0 10%; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }

  .header h1 {
    margin-right: 10px;
  }

  .header-title {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  
  .main-image {
    width: 100%;
    height: 25rem;
    z-index: 0;
    overflow: hidden;
  }
  
  .main-image img {
    width: 110%;
    height: 100%;
    object-fit: cover;
    transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
  }

  @media screen and (max-width: 500px) {
    .header h1 {
      margin-right: 10px;
      font-size: 20px;
    }
  }